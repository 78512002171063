<template>
  <cp-general-modal
    ref="createModal"
    :title="$t('createInvestorModal.title')"
    :default-value="defaultFormValue"
    title-icon="ion ion-ios-person-add"
    :ok-disabled="disabled"
    @onOk="saveChanges"
    @onInput="modalIsUpdated"
  >
    <cp-select
      name="investorType"
      :label="$t('createInvestorModal.input.investorType')"
      :options="investorType"
      @input="onInvestorType"
    />
    <div
      v-if="isIndividual"
      class="d-flex justify-content-between"
    >
      <div class="w-100 mr-3">
        <cp-input
          key="firstName"
          name="firstName"
          :label="$t('createInvestorModal.input.firstName')"
        />
      </div>

      <div class="w-100">
        <cp-input
          key="middleName"
          name="middleName"
          :label="$t('createInvestorModal.input.middleName')"
        />
      </div>
    </div>
    <cp-input
      v-if="isEntity"
      key="entityName"
      name="entityName"
      :label="$t('createInvestorModal.input.entityName')"
    />
    <cp-input
      v-if="isEntity"
      key="entityDba"
      name="entityDba"
      :label="$t('createInvestorModal.input.entityDba')"
    />
    <cp-input
      v-if="isEntity"
      key="entityLeiCode"
      name="entityLeiCode"
      :label="$t('createInvestorModal.input.entityLeiCode')"
      validate="min:20|max:20|regex:^[A-Za-z0-9]*$"
    />
    <p
      v-if="isSpecialChars"
      class="error-msg"
    >
      No special characters allowed
    </p>
    <cp-input
      v-if="isIndividual"
      key="lastName"
      name="lastName"
      :label="$t('createInvestorModal.input.lastName')"
    />

    <cp-input
      name="email"
      :label="$t('createInvestorModal.input.email')"
      validate="required|email"
    />

    <cp-select
      name="countryCode"
      placeholder
      :label="$t('createInvestorModal.input.country')"
      validate="required"
      :options="countryList"
    />

    <cp-select
      v-show="isUsa"
      placeholder
      name="state"
      :label="$t('createInvestorModal.input.state')"
      :validate="isUsa ? 'required' : null"
      :options="stateList"
    />
    <cp-select
      v-if="isEntity"
      key="entityType"
      placeholder
      name="entityType"
      :label="$t('createInvestorModal.input.entityType')"
      :options="entityType"
    />
    <cp-select
      v-show="canAddFboInvestor && custodiansList.length"
      key="custodianId"
      placeholder
      name="custodianId"
      :label="$t('createInvestorModal.input.custodian')"
      :options="getCustodiansList"
    />
  </cp-general-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Cookie from 'js-cookie';
import routeNames from '~/utilities/routes';
import states from '~/utilities/us-states-list';

import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';

const disableNewEntityTypes = process.env.VUE_APP_DISABLE_NEW_ENTITY_TYPES === 'true';

export default {
  name: 'CreateEntity',
  components: {
    CpGeneralModal,
    CpInput,
    CpSelect,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      states,
      disabled: false,
      isUsa: false,
      isEntity: false,
      isIndividual: true,
      isSpecialChars: false,
      defaultFormValue: { investorType: 'individual' },
      investorType: [
        { value: 'individual', i18nKey: 'common.category.investorType.individual' },
        { value: 'entity', i18nKey: 'common.category.investorType.entity' },
      ],
      entityType: [
        { value: 'revocable-trust', i18nKey: 'common.category.entityType.revocableTrust' },
        { value: 'irrevocable-trust', i18nKey: 'common.category.entityType.irrevocableTrust' },
        { value: 'limited-general-partnership', i18nKey: 'common.category.entityType.limitedGeneralPartnership' },
        { value: 'llc', i18nKey: 'common.category.entityType.llc' },
        { value: 'corporation', i18nKey: 'common.category.entityType.corporation' },
        { value: 'joint-account', i18nKey: 'common.category.entityType.jointAccount' },
        ...(disableNewEntityTypes ? [] : [
          { value: 'for-profit-foundation', i18nKey: 'common.category.entityType.forProfitFoundation' },
          { value: 'non-profit-foundation', i18nKey: 'common.category.entityType.nonProfitFoundation' },
        ]),
        { value: 'fund', i18nKey: 'common.category.entityType.fund' },
        { value: 'other', i18nKey: 'common.category.entityType.other' },
      ],
    };
  },
  computed: {
    ...mapState('issuersInfo', ['currentToken']),
    ...mapState('currentOperator', ['authorizationLevel']),
    ...mapState('custodians', ['custodiansList']),
    countryList() {
      return (this.options || []).filter(({ value }) => value);
    },
    stateList() {
      return Object.keys(this.states).map(key => ({ value: key, text: this.states[key] }));
    },
    getCustodiansList() {
      const custodianList = this.custodiansList.map(custodian => ({ value: custodian.id, text: custodian.name }));
      custodianList.push({ value: 0, text: 'None' });
      return custodianList;
    },
    canAddFboInvestor() {
      return ['megaadmin', 'superadmin', 'brokerdealer'].includes(this.authorizationLevel);
    },
  },
  created() {
    const { idIssuer: issuerId } = this.$route.params;
    if (this.canAddFboInvestor) this.getCustodians({ params: { issuerId } });
  },
  methods: {
    ...mapActions('investors', ['addInvestor']),
    ...mapActions('custodians', ['getCustodians']),
    show() {
      this.isEntity = false;
      this.isIndividual = !this.isEntity;
      this.$refs.createModal.show();
    },
    modalIsUpdated({ countryCode }) {
      this.isUsa = countryCode === 'US';
    },
    saveChanges(body) {
      this.disabled = true;
      if (body.custodianId === 0) body.custodianId = undefined;
      if (body.entityLeiCode === '') body.entityLeiCode = null;
      const { idIssuer: issuerId } = this.$route.params;
      this.addInvestor({
        body,
        investorsType: {
          issuerId,
          view: Cookie.get('currentTypeInvestors'),
        },
      }).then(({ data }) => {
        if (this.currentToken && this.currentToken.id) {
          this.$router.push(routeNames.viewDetails(issuerId, this.currentToken.id, data.id));
        }
      }).finally(() => {
        this.disabled = false;
      });
    },
    onInvestorType(val) {
      this.isEntity = val === 'entity';
      this.isIndividual = !this.isEntity;
    },
  },
};
</script>
<style>.error-msg{
  font-size: 85%;
  color: #f7321e;
  margin: 0.25rem;
  display: block !important;
}
</style>
