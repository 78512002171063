<template>
  <cp-general-modal
    ref="createModal"
    title="Add Omnibus Wallet Owner"
    title-icon="ion ion-ios-person-add"
    :ok-disabled="disabled"
    @onOk="saveChanges"
    @onInput="modalIsUpdated"
  >
    <div class="d-flex justify-content-between">
      <div class="w-100 mr-3">
        <cp-input
          name="name"
          label="Entity Name"
          validate="required"
        />
      </div>

      <div class="w-100">
        <cp-input
          name="email"
          label="Entity Email"
          validate="required|email"
        />
      </div>
    </div>
    <cp-select
      name="countryCode"
      placeholder
      label="Country"
      validate="required"
      :options="countryList"
    />
    <cp-select
      v-show="isUsa"
      placeholder
      name="state"
      label="State"
      :validate="isUsa ? 'required' : null"
      :options="stateList"
    />
    <cp-input
      name="address"
      label="Wallet Address"
      validate="required"
      :options="countryList"
    />
  </cp-general-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import states from '~/utilities/us-states-list';

import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';

export default {
  name: 'CpCreateOmnibusWalletOwner',
  components: {
    CpGeneralModal,
    CpInput,
    CpSelect,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      states,
      disabled: false,
      isUsa: false,
    };
  },
  computed: {
    ...mapGetters('issuersInfo', ['issuerInfo', 'getIssuerId']),
    blockchain() {
      return this.issuerInfo.blockchain;
    },
    countryList() {
      return (this.options || []).filter(({ value }) => value);
    },
    stateList() {
      return Object.keys(this.states).map(key => ({ value: key, text: this.states[key] }));
    },
  },
  methods: {
    ...mapActions('omnibusWalletOwner', ['createOmnibusWalletOwner']),
    show() {
      this.$refs.createModal.show();
    },
    modalIsUpdated({ countryCode }) {
      this.isUsa = countryCode === 'US';
    },
    saveChanges(body) {
      this.disabled = true;
      const { tokenId } = this.$route.params;

      this.createOmnibusWalletOwner({
        body,
        issuerId: this.getIssuerId,
        tokenId,
      }).then(({ data }) => {
        this.$router.push({ name: 'omnibusWalletOwnerDetails', params: { idIssuer: this.getIssuerId, tokenId, detailsId: data.id } });
      }).finally(() => {
        this.$refs.createModal.hide();
        this.disabled = false;
      });
    },
  },
};
</script>
